<template lang="html">
    <div class="portrait position-relative w-100 d-flex align-items-start align-content-start mb-lg-5 mb-3">
        <img :src="image" class="w-100 h-100 object-fit-cover">
        <span class="phrases font-1" :style="{'line-height': (phrase3 == '' || phrase3 === null ? '0' : '7vw')}">
            <span class="phrase1 ts-1 animation effect-2">{{phrase1}}</span>
            <span class="phrase2 ts-1 animation effect-3">{{phrase2}}</span>
            <span class="phrase3 ts-5 font-2">{{phrase3}}</span>
        </span>
    </div>
</template>
<script>
export default {
    name:"menuContent",
    props:{
        phrase1:"",
        phrase2:"",
        phrase3:"",
        image:null,
    },
    mounted(){
        this.$parent.$parent.$parent.$emit('event', {type:'updateObserver'})
    },
}
</script>
<style lang="css">
    .phrases{
        color:var(--text1);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: rgba(64, 64, 64, 0.61);
    }
    .phrase3{
        width: 100%;
        text-align: center;
    }

</style>