<template lang="html">
    <div class="d-flex flex-wrap w-100 align-items-start align-content-start justify-content-center">

        <div class="col-12 px-2 px-md-0 d-flex justify-content-center py-4 mt-lg-5 mb-lg-5 mt-1 mb-1">
            <span class="col-11 border border-bottom border-1 border-s-1"></span>
        </div>

        <div class="trademarks animation effect-1 col-11 px-2 d-flex flex-wrap align-items-center flex-lg-nowrap justify-content-center py-4 py-md-0">
            <span role="button" class="col-6 col-md-3 col-lg-3 h-100 px-2 px-lg-5 mb-5 mb-md-0 position-relative" @click="bill('bostons')" pos="1">
                <div class="circledAddon"><span></span><span></span></div>
                <img :src="a25" class="p-2 pe-none h-100 s1 w-100 object-fit-contain">
            </span>
            <span role="button" class="col-6 col-md-3 col-lg-3 h-100 px-2 px-lg-5 mb-5 mb-md-0 position-relative" @click="bill('parroquia')" pos="3">
                <div class="circledAddon"><span></span><span></span></div>
                <img :src="a26" class="p-2 pe-none h-100 s1 w-100 object-fit-contain" >
            </span>
            <a role="button" class="col-6 col-md-3 col-lg-3 h-100 px-2 px-lg-5 position-relative" :href="this.cities.sushiroll" target="_blank" pos="2">
                <div class="circledAddon"><span></span><span></span></div>
                <img :src="a24" class="p-2 pe-none h-100 s1 w-100 object-fit-contain">
            </a>
            <a role="button" class="col-6 col-md-3 col-lg-3 h-100 px-2 px-lg-5  position-relative" :href="this.cities.santaclara" target="_blank" pos="4">
                <div class="circledAddon"><span></span><span></span></div>
                <img :src="a27" class="p-1 pe-none h-100 s2 w-100 object-fit-contain" style="transform:scale(1.3)">
            </a>
        </div>

        <div class="modal fade" id="modalBostons" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg px-md-0 px-2" >
                <div class="modal-content" v-bind:style="{ 'background-image': 'url(' + a4 + ')' }">
                    
                    <div class="modal-header border-0 justify-content-end py-1">
                        <div class="w-100 d-flex justify-content-center">
                            <img :src="a25" class="icon col-8 col-md-4 mt-2">
                        </div>
                        <button type="button" class="position-absolute end-0 top-0 mt-3 me-3 w-auto h-100 float-end border-0 d-flex justify-content-start bg-transparent ts-12 font-2" data-bs-dismiss="modal" aria-label="Close">✖</button>
                    </div>
                    <div class="modal-body d-flex flex-wrap justify-content-center pt-3 pb-3 px-5">
                        <div class="w-100 d-flex justify-content-center ts-4 color-1 my-2">Selecciona la ciudad donde nos visitaste</div>
                        <template v-for="(city, cityKey) in cities.bostons">
                            <div class="col-12 d-flex justify-content-center my-2">
                                <a class="position-relative d-flex align-items-center align-content-center text-center py-3 px-3 col-lg-8 col-12 color-1 selectOption text-decoration-none" :href="city.url" @click="closeModal('bostons')" target="_blank"><div class="col-md-5 col-4 pe-2 d-flex justify-content-end"><img :src="city.image" class="cityImage"></div><div class="col-8 col-md-7 text-start ps-3">{{cityKey}}</div></a>
                            </div>
                        </template>
                    </div>

                </div>
            </div>
        </div>

        <div class="modal fade" id="modalParroquia" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg px-md-0 px-2" >
                <div class="modal-content" v-bind:style="{ 'background-image': 'url(' + a4 + ')' }">
                    
                    <div class="modal-header border-0 justify-content-end py-1 position-relative">
                        <div class="w-100 d-flex justify-content-center">
                            <img :src="a26" class="icon col-8 col-md-4 mt-2">
                        </div>
                        <button type="button" class="position-absolute end-0 top-0 mt-3 me-3 w-auto h-100 float-end border-0 d-flex justify-content-start bg-transparent ts-12 font-2" data-bs-dismiss="modal" aria-label="Close">✖</button>
                    </div>
                    <div class="modal-body d-flex flex-wrap justify-content-center pt-3 pb-3 px-5">
                        <div class="w-100 d-flex justify-content-center ts-4 color-1 my-2">Selecciona la ciudad donde nos visitaste</div>
                        <template v-for="(city, cityKey) in cities.parroquia">
                            <div class="col-12 d-flex justify-content-center my-2">
                                <a class="position-relative d-flex align-items-center align-content-center text-center py-3 px-3 col-lg-8 col-12 color-1 selectOption text-decoration-none" :href="city.url" @click="closeModal('parroquia')" target="_blank"><div class="col-md-5 col-4 pe-2 d-flex justify-content-end"><img :src="city.image" class="cityImage"></div><div class="col-8 col-md-7 text-start ps-3">{{cityKey}}</div></a>
                            </div>
                        </template>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 px-2 px-md-0 d-flex justify-content-center py-4 mt-lg-5 mb-lg-1 mt-0 mb-1">
            <span class="col-11 border border-bottom border-1 border-s-1"></span>
        </div>

        <div class="col-12 px-2 px-md-0 d-flex flex-wrap justify-content-center">

            <div class="col-11 mt-2 me-md-3 me-1">
                <div class="w-100 color-1 fw-bold ts-12 text-start d-flex justify-content-start">Solicita tu factura</div> 
            </div>

            <div class="col-11 mt-2 me-md-3 me-1 d-flex flex-wrap mt-2 mt-md-4 pt-4">
                <div class="col-xl-4 col-lg-6 col-12 px-3 d-flex flex-wrap justify-content-start mt-2 mb-3 mb-md-5">
                    <div class="col-12 text-start ts-4 color-1 fw-bold">
                        1.- Selecciona la marca
                    </div>
                    <div class="col-12 text-start mt-4 mt-md-4 ts-3 color-2 text-start ps-md-3 ps-2">
                        Elige la marca del restaurante en donde realizaste tu consumo, dándole click.
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-12 px-3 d-flex flex-wrap justify-content-start mt-2 mb-3 mb-md-5">
                    <div class="col-12 text-start ts-4 color-1 fw-bold">
                        2.- Ingresa tu información
                    </div>
                    <div class="col-12 text-start mt-4 mt-md-4 ts-3 color-2 text-start ps-md-3 ps-2">
                        Introduce los datos requeridos en el formulario y genera la factura.
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-12 px-3 d-flex flex-wrap justify-content-start mt-2 mb-3 mb-md-5">
                    <div class="col-12 text-start ts-4 color-1 fw-bold">
                        3.- Descarga tu factura
                    </div>
                    <div class="col-12 text-start mt-4 mt-md-4 ts-3 color-2 ps-md-3 ps-2">
                        Obtén el comprobante de factura digital en tu dispositivo.
                    </div>
                </div>
                <div class="col-xl-7 col-lg-6 col-12 px-3 d-flex flex-wrap justify-content-start mt-2 mb-3 mb-md-5">
                    <div class="col-12 text-start ts-4 color-1 fw-bold">
                        Te recordamos:
                    </div>
                    <div class="col-12 text-start mt-4 mt-md-4 ts-3 color-2 text-start ps-md-3 ps-2">
                        Tienes 3 días a partir de la fecha de tu compra para facturar. Si tienes alguna pregunta o necesitas ayuda con algo, no dudes en contactarnos. ¡Gracias por visitarnos!
                    </div>
                </div>
            </div>
        </div>

        <footer-info class="mt-5"></footer-info>
    </div>
</template>
<script>

import footerInfo from "@/components/common/footer.vue";
import { Modal } from "bootstrap";

export default {
    name:"billing",
    components:{
        footerInfo
    },
    data(){
        return {
            a4: require("@/assets/images/banners/a4.jpg"),
            a26: require("@/assets/images/a26.png"),
            a27: require("@/assets/images/a27.png"),
            a24: require("@/assets/images/a24.png"),
            a25: require("@/assets/images/a25.png"),
            a73: require("@/assets/images/a73.png"),
            cities:{
                'bostons':{
                    'MÉRIDA':{url:"https://facturacion.bptgroup.mx/bostons/mid/", image:require("@/assets/images/banners/a10.jpg")},
                    'CAMPECHE':{url:"https://facturacion.bptgroup.mx/bostons/cpe/", image:require("@/assets/images/banners/a11.jpg")},
                    'CD. DE MEXICO':{url:"https://facturacion.bptgroup.mx/bostons/mex/", image:require("@/assets/images/banners/a12.jpg")},
                    'QUERÉTARO':{url:"https://facturacion.bptgroup.mx/bostons/qro/", image:require("@/assets/images/banners/a13.jpg")},
                },
                'parroquia':{
                    'MÉRIDA':{url:"https://facturacion.bptgroup.mx/parroquia/mid/", image:require("@/assets/images/banners/a10.jpg")},
                    'CAMPECHE':{url:"https://facturacion.bptgroup.mx/parroquia/cpe/", image:require("@/assets/images/banners/a11.jpg")},
                    'CD. DE MEXICO':{url:"https://facturacion.bptgroup.mx/parroquia/mex/", image:require("@/assets/images/banners/a12.jpg")},
                },
                'santaclara':"http://www.facturar-t.com/Portal_4.0/",
                'sushiroll':"https://sushiroll.com.mx/facturacion/"
            },
            modals:{
                bostons:null,
                parroquia:null,
            }
        }
    },
    mounted(){
        this.$scrollInTop();
        this.modals.bostons = new Modal(document.getElementById("modalBostons"), {
            backdrop: "static",
            keyboard: false,
        });
        this.modals.parroquia = new Modal(document.getElementById("modalParroquia"), {
            backdrop: "static",
            keyboard: false,
        });
        this.$parent.$parent.$emit('event', {type:'updateObserver'})
    },
    beforeUnmount(){
        this.modals.bostons.hide()
        this.modals.parroquia.hide()
    },
    methods:{
        bill(page){
            switch(page){
                case "bostons":
                    this.modals.bostons.show()
                break;
                case "parroquia":
                    this.modals.parroquia.show()
                break;
            }
        },
        closeModal(page){
            this.modals[page].hide()
        },
    }
}
</script>
<style lang="css">
    .modal-content{
        background-size: cover !important;
        background-repeat: no-repeat;
    }
    .trademarks{
        min-height: 120px;
    }

    .trademarksShow{
        min-height: 33vw;
    }

    .animation.effect-1{
        opacity: 0;
        -webkit-transition: 0.8s ease;
        -moz-transition: 0.8s ease;
        -o-transition: 0.8s ease;
        -ms-transition: 0.8s ease;
        transition: 0.8s ease;
        transform: translateY(-5vw);
    }

    .animation.effect-1-active{
        opacity: 1 !important;
        transform: translateY(0vw) !important;
    }

    .selectOption{
        background-color: var(--tredecagenary);
        border-radius: 6px;
        border:2px solid #dae2fe;
        opacity:0;
        animation:append 0.4s linear 1 forwards;
        -webkit-transition: append 0.4s linear 1 forwards;
        -moz-transition:append 0.4s linear 1 forwards;
        -o-transition: append 0.4s linear 1 forwards;
        -ms-transition: append 0.4s linear 1 forwards
    }

    .selectOption .cityImage{
        border-radius: 50%;
        overflow: hidden;
        height: 50px;
        width: 50px;
        object-fit: cover;
    }

    .selectOption:hover{
        color:var(--tertiary)
    }

    .trademarks .position-relative:hover img.s1{
        transform: scale(1.2);
    }
    .trademarks .position-relative:hover img.s2{
        transform: scale(1.5) !important;
    }

    .trademarks .position-relative::after{
        content:"";
        width:20px;
        height: 20px;
        background-image:url("@/assets/images/a73.png");
        background-size:20px 20px;
        position:absolute;
        right:50px;
        bottom:0px;
        opacity: 0;
        z-index: 10;
    }

    .trademarks .position-relative .circledAddon{
        width:20px;
        height: 20px;
        position:absolute;
        right:50px;
        bottom:0px;
        opacity: 0;
        z-index: 9;
    }

    .trademarks .position-relative .circledAddon span:nth-child(1){
        position:absolute;
        right:5px;
        bottom:5px;
        border-radius: 50%;
        border:2px solid #0066cc;
        width:100%;
        height: 100%;
        animation:cicling 0.6s linear 0s infinite;
    }
    .trademarks .position-relative .circledAddon span:nth-child(2){
        position:absolute;
        right:5px;
        bottom:5px;
        border-radius: 50%;
        border:2px solid #0066cc;
        width:100%;
        height: 100%;
        animation:cicling 0.6s linear 0.2s infinite;
    }

    .trademarks .position-relative[pos="1"] .circledAddon{
        animation:clickAppend 8s linear 2s 2;
    }

    .trademarks .position-relative[pos="1"]::after{
        animation:clickAppend 8s linear 2s 2;
    }

    .trademarks .position-relative[pos="1"]{
        animation:click 8s linear 2s 2;
    }

    .trademarks .position-relative[pos="2"] .circledAddon{
        animation:clickAppend 8s linear 4s 2;
    }

    .trademarks .position-relative[pos="2"]::after{
        animation:clickAppend 8s linear 4s 2;
    }

    .trademarks .position-relative[pos="2"]{
        animation:click 8s linear 4s 2;
    }

    .trademarks .position-relative[pos="3"] .circledAddon{
        animation:clickAppend 8s linear 6s 2;
    }

    .trademarks .position-relative[pos="3"]::after{
        animation:clickAppend 8s linear 6s 2;
    }

    .trademarks .position-relative[pos="3"]{
        animation:click 8s linear 6s 2;
    }

    .trademarks .position-relative[pos="4"] .circledAddon{
        animation:clickAppend 8s linear 8s 2;
    }

    .trademarks .position-relative[pos="4"]::after{
        animation:clickAppend 8s linear 8s 2;
    }

    .trademarks .position-relative[pos="4"]{
        animation:click 8s linear 8s 2;
    }

    @keyframes click {
        0%{
            transform: scale(1);
        }
        2.5%{
            transform: scale(1);
        }
        5%{
            transform: scale(0.8);
        }
        7.5%{
            transform: scale(1);
        }
        100%{
            transform: scale(1);
        }
    }

    @keyframes cicling {
        0%{
            transform: scale(0);
        }
        100%{
            transform: scale(1);
        }
    }

    @keyframes clickAppend {
        0%{
            transform: scale(1);
        }
        2%{
            opacity: 0;
        }
        2.5%{
            transform: scale(1);
            opacity: 1;
        }
        5%{
            transform: scale(0.8);
        }
        7.5%{
            opacity: 1;
            transform: scale(1);
        }
        15%{
            opacity: 1;
        }
        15.5%{
            opacity: 0;
        }
        100%{
            transform: scale(1);
        }
    }

    @keyframes append {
        0%{
            transform: scaleY(0);
            opacity: 0;
        }
        5%{
            opacity: 0;
        }
        100%{
            opacity: 1;
            transform: scaleY(1)
        }
    }
</style>