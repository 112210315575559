import { createApp } from 'vue'
import App from './App.vue'
import VueMatomo from 'vue-matomo'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import '@/assets/scss/globalColors.scss';
import '@/assets/css/general.css';

const app = createApp(App);

app.config.globalProperties.$scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Omitir para un salto instantáneo sin animación
  });
};

app.config.globalProperties.$scrollInTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'instant' // Omitir para un salto instantáneo sin animación
  });
};

app.config.globalProperties.$filters = {
    cap(data) {
        var capitalized = []
        data.split(' ').forEach(word => {
          capitalized.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
          )
        })
        return capitalized.join(' ')
    },
    cash(value){
      if (typeof value !== "number") {
          return value;
      }
      var formatter =  value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
      return formatter
    },
    cash2(value){
      if (typeof value !== "number") {
          return value;
      }
      var formatter =  value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      return formatter
    },
    resVal(res) {
      if(!res){
        return false
      }
      if(res.code != "200"){
          return false
      }
      if(res.data === undefined){
          return false
      }
      return true
    },
    resErr(res) {
      if(!res){
        return false
      }
      if(res.msj === undefined){
          return false
      }
      return true
    }
}


app.use(router);
app.use(VueMatomo, {
  host: 'https://dashboard.efisense.net/analytics',
  siteId: 5,
});

app.mount('#app');
window._paq.push(['trackPageView']);
