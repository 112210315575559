<template lang="html">
    <div class="memberStatus d-flex flex-wrap w-100 pb-5 align-items-start align-content-center justify-content-center px-0 px-md-5" v-if="charged">
        <template v-if="userStatus">
            <div class="col-10 col-md-9 d-flex overflow-hidden px-2 py-0 py-md-5 flex-wrap align-items-center align-content-center flex-lg-nowrap justify-content-lg-between justify-content-center">

                <div class="col-xxl-2 col-xl-3 col-lg-4 col-12 justify-content-center d-flex align-items-start align-content-start">
                    <div class="col-xl-12 col-lg-12 col-md-5 col-9 justify-content-center d-flex align-items-start align-content-start">
                        <div class="perfectSquare placeholder">
                            <img :src="a82" class="statusIcon">
                            <img :src="getAvatar(userData.avatar, userData.gender)" alt="" class="w-100 h-100 itemImage circled imgHidden avatar" @load="display($event, false)" @error="display($event, true)">
                        </div>
                    </div>
                </div>

                <div style="width:100px" class="d-none d-lg-flex">
                </div>

                <div style="flex:1" class="d-flex flex-wrap justify-content-start align-items-start align-content-start mt-5 mt-lg-0">
                    <div class="col-12 ps-0 d-flex flex-wrap justify-content-between align-content-end align-items-end bordered">
                        <div class="col-12 d-flex flex-wrap">
                            <div class="col-12 ps-0 ts-25 text-start fw-bold color-1 align-items-center align-content-center d-flex" style="line-height:1.2em">
                                {{userData.fullName}}
                                <div class="d-flex align-items-center align-content-center d-flex pt-1">
                                    <span class="pointActive ms-md-2 ms-3 me-md-2 me-3"></span>
                                    <span class="textActive h-100 pt-md-1" v-if="userData.gender == 'female'">Activa</span>
                                    <span class="textActive h-100 pt-md-1" v-else>Activo</span>
                                </div>
                            </div>
                            <div class="col-11 pb-4 pt-3 pb-md-4 pt-md-3 ps-0 ts-26 text-start ms-3 fw-bold d-flex align-items-center align-content-center lh-0">{{userData.job}}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 ps-0 py-md-4 py-4 d-flex flex-wrap justify-content-between align-content-center align-items-center bordered lh-0">
                        <div class="col-12 col-md-8 ps-5 ts-26 text-start d-flex flex-wrap align-content-center align-items-center h-100 pt-1">
                            <span class="d-flex d-md-none">Sucursal&nbsp;</span>{{userData.brand.store.storeName}}
                        </div>
                        <div class="col-12 col-md-4 ts-24 col-md-3 font-2 d-none d-md-flex justify-content-end align-content-center align-items-center h-100 pe-4 pt-1">
                            Sucursal
                        </div>
                    </div>
                    <div v-if="userData.amount != undefined" class="col-12 ps-5 py-md-4 py-4 d-flex flex-wrap justify-content-between align-content-center align-items-center bordered lh-0">
                        <div class="col-12 col-md-8 ps-5 ts-26-2 text-start d-flex flex-wrap align-content-center align-items-center h-100 pt-1">
                            <span class="d-flex d-md-none">Saldo&nbsp;</span>$ {{$filters.cash2(userData.amount | 0)}}&nbsp;MXN
                        </div>
                        <div class="col-12 col-md-4 ts-24 col-md-3 font-2 d-none d-md-flex justify-content-end align-content-center align-items-center h-100 pe-4 pt-1">
                            Saldo
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-9 d-flex justify-content-start align-items-start align-content-start mt-2 mt-md-0">
                <div class="col-xxl-2 col-xl-3 col-lg-4 col-12 justify-content-center d-flex">
                    <div class="col-xl-12 col-lg-12 col-md-5 col-9 justify-content-center d-flex">
                       <img :src="getFranchiseImage(userData.brand.logo)" alt="" class="w-100 mt-2">
                    </div>
                </div>
            </div>
        </template>
        
        <template v-else>
            <div class="col-10 col-md-9 d-flex overflow-hidden px-2 py-0 py-md-5 flex-wrap align-content-center align-items-center flex-lg-nowrap justify-content-lg-between justify-content-center">

                <div class="col-xl-3 col-lg-2 col-12 justify-content-center d-flex">
                    <div class="col-xl-12 col-lg-12 col-md-5 col-9 justify-content-center d-flex">
                        <img :src="a80" alt="" class="w-100">
                    </div>
                </div>

                <div style="width:100px" class="d-none d-xl-flex">
                </div>

                <div style="flex:1" class="d-flex flex-wrap justify-content-start mt-5 mt-lg-0">
                    <div class="col-12 ps-0 pb-2 d-flex flex-wrap justify-content-between align-content-end align-items-end">
                        <div class="col-12 col-md-12 ps-lg-4 ps-xl-0 d-flex flex-wrap">
                            <div class="col-12 ps-0 ts-2 text-center text-lg-start fw-bold color-1" style="line-height:1.2em">Cuenta desactivada</div>
                            <div class="col-11 py-1 py-md-0 ps-0 ts-26 text-center text-lg-start ms-3 mt-2 mt-md-0 fw-bold">Retener tarjeta</div>
                        </div>
                    </div>
                </div>

            </div>
        </template>
    </div>

    <div v-else class="memberStatus d-flex flex-wrap w-100 pb-5 align-items-start align-content-center justify-content-center px-0 px-md-5">
        <loader :active="true"></loader>
    </div>
</template>
<script>
import loader from "@/components/common/loader.vue";
export default {
    name:"memberCard",
    components:{
        loader
    },
    props:{
        con: {
            default: {},
            type: Object
        },
    },
    data(){
        return{
            a82:require('@/assets/images/a82.png'),
            a75:require('@/assets/images/a75.jpg'),
            a80:require('@/assets/images/a80.webp'),
            storeIcons:{
                "bostons":require('@/assets/images/a72.png'),
                "sushiroll":require('@/assets/images/a8.jpg'),
                "parroquia":require('@/assets/images/a9.png'),
                "staclara":require('@/assets/images/a74.png'),
                "bpt":require('@/assets/images/a81.png'),
            },
            membership: this.$route.query.membership ? this.$route.query.membership : null,
            userStatus:false,
            charged:false,
            userData:null
        }
    },
    mounted(){
        this.$scrollInTop();
        if(this.membership != null){
            this.getUser(this.membership)
        }
    },
    methods:{
        display(e, error=false){
            if(error){
                e.target.src = this.a76
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay', 'error')
            }else{
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay')
            }
		},
        getAvatar(data, gender='male'){
            if(data == null || data == ''){
                switch(gender){
                    case 'male':
                        return this.a76
                    case 'female':
                        return this.a75
                    default:
                        return this.a76
                }
            }
            return data
        },
        getUser(token64){
            this.con.membership(token64).then((res)=>{
                if(res.code == 200){
                    this.userData = res.data;
                    this.userStatus = true;
                    this.charged = true;
                }else{
                    this.userStatus = false;
                    this.charged = true;
                }
            }).catch((err)=>{})
        },
        getFranchiseImage(code=""){
            switch (code) {
                case 'BOMEX':
                case 'TYZA':
                case 'BOSTONS':
                    return this.storeIcons.bostons;
                case 'SUSHI':
                    return this.storeIcons.sushiroll;
                case 'PARROQUIA':
                    return this.storeIcons.parroquia;
                case 'STACLARA':
                    return this.storeIcons.staclara;
                case 'BPT':
                    return this.storeIcons.bpt;
                default:
                    return this.storeIcons.bpt;
            }
        },
    }

}
</script>
<style lang="css">

    .memberStatus{
        min-height:calc(100vh - 100px);
    }

    .bordered{
        border-bottom:1px solid var(--secondary)
    }

    .perfectSquare{
        padding-bottom: 100%;
        width: 100%;
        flex-shrink: 0;
        position: relative;
        border-radius: 50%;
        background: #ddd;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        background-size: 800px 104px;
        overflow:visible;
        border:2px solid var(--vigequartenary)
    }
    .perfectSquare.placeholder{
        animation: placeholderer 1s infinite;
    }
    .perfectSquare .avatar{
        position: absolute;
        object-position: center center;
        object-fit: cover;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    .imgHidden{
		visibility: hidden;
	}
	.imgDisplay{
		animation: 0.3s fade 1;
	}

    .pointActive{
        display: inline-flex;
        width: 10px;
        height: 10px;
        background-color: var(--cuadecagenary);
        border-radius: 50%;
    }

    .textActive{
        display: inline;
        color: var(--cuadecagenary);
        font-size: 18px;
    }

    .statusIcon{
        position: absolute;
        right:-15%;
        bottom: 0%;
        width: 40%;
        z-index: 10;
        animation:activeEffect 5s ease infinite;
        will-change: filter;
        transition: filter .5s ease;
    }
    

    @keyframes activeEffect {
        50%{
            filter: drop-shadow(-2px -2px 4px rgb(38, 153, 251, 0.7));
            filter: drop-shadow(2px -2px 4px rgb(38, 153, 251, 0.7));
            filter: drop-shadow(2px 2px 4px rgb(38, 153, 251, 0.7));
            filter: drop-shadow(2px 2px 4px rgb(38, 153, 251, 0.7));
        }
    }
</style>