<template lang="html">
    <div class="w-100 d-flex flex-wrap mt-5">
        <div class="block w-100 d-flex flex-wrap justify-content-center justify-content-md-evenly px-0 pt-md-3 pb-md-5 mt-1 mt-md-5" :style="{'background-color':color2, 'color':color3}">

            <div class="col-7 col-md-2 mt-4 mb-3 d-flex flex-wrap flex-column">
                <img :src="image" class="animation effect-7">
                <div class="w-100 d-md-flex justify-content-center align-items-center d-none mt-5 mb-4" style="flex:1">
                    <a :href="'https://'+link" class="d-inline-block btn-go px-4 py-2 ts-9 fw-bold text-decoration-none" :style="{'background-color':color1, 'color':color4}">{{link}}</a>
                </div>
            </div>

            <div class="col-11 col-md-5 mt-3 px-4 d-flex flex-wrap pt-md-3">
                <div class="w-100 fw-1 ts-7 mb-3 text-center text-md-start">{{title}}</div>
                <span class="w-100 ts-9 text-center text-md-start ps-md-4">{{description}}</span>
                <div class="col-12 ts-8 mt-3 mt-md-5 mb-md-5 mb-2 text-center text-md-start ps-md-5 underlineStyle fw-bold">{{phrase}}</div>
            </div>
            
            <div class="col-10 col-md-2 mt-3 d-flex flex-wrap flex-column align-content-start align-items-start">
                <span class="w-100 text-start ts-7 fw-bold mb-2 mb-md-3 pt-md-3">Sucursales</span>
                <div class="stores col-9 col-md-12 ms-3 d-flex flex-wrap justify-content-start align-content-start align-items-start" style="flex:1" :style="{'border-left':'1px solid '+color3}">
                    <template v-for="(city, sidx) in stores">
                        <div class="col-11 text-start ps-3 mb-3">
                            <span class="fw-bold ts-8">{{sidx}}:</span>
                            <template v-for="store in city">
                                <div class="col-11 text-start ps-4 ts-9">{{store}}</div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>

            <div class="col-7 mt-5 mb-4 d-md-none d-block"><a :href="'https://'+link" class="d-inline-block btn-go px-5 py-2 ts-8 fw-bold text-decoration-none" :style="{'background-color':color1, 'color':color4}">{{link}}</a></div>

            <span class="w-100 d-none d-md-block" style="height:100px;"></span>

        </div>
        <div class="pictures py-4 col-12 col-md-12 d-flex flex-wrap justify-content-center align-content-stretch px-2 animation effect-1">
            <template v-for="picture in pictures">
                <div class="col-6 col-md-2 d-flex squarePerfect position-relative mx-md-4">
                    <span class="squareContent p-2 px-md-0">
                        <img :src="picture">
                    </span>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name:"markDescription",
    props:{
        image:null,
        title:"",
        description:"",
        link:"",
        phrase:"",
        stores:{},
        color1:"#000",
        color2:"#fff",
        color3:"#ccc",
        color4:"#fff",
        pictures:{
            default:[],
            typeof: Array
        },
    },
}
</script>
<style lang="css">
    .block{
        min-height: 400px;
    }
    .block img{
        width: 100%;
        object-fit: cover;
    }
    .btn-go{
        border: 0;
        outline:none;
        border-radius: 14px;
    }
    .squareContent img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .squarePerfect:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .squareContent{
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        left: 0;
        top:0
    }
    .animation.effect-2{
        transform: scale(0);
    }
</style>