<template lang="html">
    <div id="error" class="d-flex flex-wrap justify-content-center align-items-center align-content-center">
        <div class="txtErr d-flex align-items-center align-content-center">4<img :src="a11" class="imgErr">4</div>
        <div class="errorLabel w-100 ts-1 text-center font-1 color-1 fw-bold my-4">
            Ooops!
        </div>
        <div class="w-100 ts-6 text-center font-3 fw-bold">
            Página no encontrada
        </div>
    </div>
</template>
<script>
export default {
    name:"NotFound",
    data(){
        return{
            a11:require('@/assets/images/a11.png')
        }
    },
    mounted(){
        this.$scrollInTop();
    }
}
</script>
<style lang="css">
    #error{
        width: 100vw;
        min-height: calc(100vh - 100px);
    }
    .imgErr{
        height: 200px;
        width: 200px;
        margin-bottom: 30px;
        opacity: 0;
        animation: errorAppend 0.5s linear 0.5s 1 forwards;
       
    }
    .txtErr{
        font-size: 260px;
        line-height: 0;
    }
    .errorLabel{
        animation: error 10s infinite linear;
    }
    @keyframes error {
        0%{
            transform: translateY(0px);
        }
        25%{
            transform: translateY(-10px);
        }
        50%{
            transform: translateY(0px);
        }
        75%{
            transform: translateY(10px);
        }
        100%{
            transform: translateY(0px);
        }
    }
    @keyframes errorAppend {
        0%{
            opacity: 0;
            transform: scale(10);
        }
        100%{
            opacity: 1;
            transform: scale(1);
        }
    }
</style>