<template>
    <menu-top :user="user" @event="filterEvent"></menu-top>
    <router-view v-slot="{ Component, route }"  @event="filterEvent">
        <transition :name="route.meta.transition" :mode="route.meta.mode">
            <component :is="Component" :con="con" class="font-1"/>
        </transition>
    </router-view>
</template>

<script>
import  { request } from '@/assets/js/requests.js';
import menuTop from "@/components/common/headMenu.vue";
import auth from '@/assets/js/auth.js'

export default {
    name:"appGeneral",
    components:{
        menuTop,
        auth,
    },
    methods:{
        goToRoute(){

        }
    },
    watch:{
        $route (to, from){

        }
    } ,
    data(){
        return{
            con:new request('https://ap15.efisense.net/apiCRM/'),
            user:{name:'',lastname:''},
            observer:null,
            hidden:null,
        }
    },
    mounted(){
        if(auth.getIsLogged()){
            this.getUser();
        }
        this.loadObserver();
    },
    methods:{
        filterEvent(data){
            switch(data.type){
                case "userlogin":
                    this.getUser();
                    break;
                case "closeSesion":
                    this.user = {name:'',lastname:''}
                    break;
                case "updateObserver":
                    this.loadObserver();
                    break;
            }
        },
        getUser(){
            this.user = auth.getJwt();
        },
        loadObserver(){
            this.hidden = document.querySelectorAll(".animation");
            this.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            this.addAnim(entry.target)
                        } else {
                            this.removeAnim(entry.target)
                        }
                    });
                },
                {
                    treshold: 0.9
                    // rootMargin:"-50px"
                }
            );
            this.hidden.forEach((entry) => {
                this.observer.observe(entry);
            });
        },
        addAnim(elm){
            if(elm.classList.contains('effect-1')){
                elm.classList.add("effect-1-active");
            }else if(elm.classList.contains('effect-2')){
                elm.classList.add("effect-2-active");
            }else if(elm.classList.contains('effect-3')){
                elm.classList.add("effect-3-active");
            }else if(elm.classList.contains('effect-4')){
                elm.classList.add("effect-4-active");
            }else if(elm.classList.contains('effect-5')){
                elm.classList.add("effect-5-active");
            }else if(elm.classList.contains('effect-6')){
                elm.classList.add("effect-6-active");
            }else if(elm.classList.contains('effect-7')){
                elm.classList.add("effect-7-active");
            }else if(elm.classList.contains('effect-8')){
                elm.classList.add("effect-8-active");
            }else if(elm.classList.contains('effect-9')){
                elm.classList.add("effect-9-active");
            }else if(elm.classList.contains('effect-10')){
                elm.classList.add("effect-10-active");
            }
        },
        removeAnim(elm){
            if(elm.classList.contains('effect-7')){
                elm.classList.remove("effect-7-active");
            }
        },
    }
}
</script>


<style lang="scss">

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        display: flex;
        flex-wrap:wrap;
        align-content: flex-start;
        align-items: flex-start;
    }

    /* transiciones */

    .scale-enter-active,
    .scale-leave-active {
        transition: all 0.1s ease;
    }
    .scale-enter-from,
    .scale-leave-to {
        opacity: 0;
        transform: scale(0.9);
    }
    

</style>
