<template lang="html">
    <div class="d-flex flex-wrap w-100 align-items-start align-content-start justify-content-center">
        <portrait :image="a1" :phrase1="'Nosotros'" :phrase2="null" :phrase3="'Lo que somos hoy en día'"></portrait>

        <div class="w-100 d-flex flex-wrap justify-content-center my-3 my-md-0">
            <div class="col-3 col-md-2 px-lg-5 logo animation effect-1">
                <img :src="a29" alt="">
            </div>
        </div>

        <div class="ts-6 color-1 fw-1 my-5 my-md-5">Hoy en día, contamos con 4 marcas <br class="d-md-none d-block"> y existen más de 18 unidades en 4<br class="d-md-block d-none"> estados<br class="d-md-none d-block"> de la República.</div>

        <div class="ts-6 color-2 fw-1 my-4 mt-md-5 mb-md-5">Estos números indican el compromiso<br class="d-md-none d-block"> que tenemos con nuestros<br class="d-md-block d-none"> colaboradores<br class="d-md-none d-block"> y socios.</div>

        <div class="ts-6 color-2 fw-1 my-4 my-md-2">Seguimos trabajando en un plan de<br class="d-md-none d-block"> expansión para seguir creando<br class="d-md-block d-none"><br class="d-md-none d-block"> experiencias wow.</div>

        <div class="vision w-100 mt-5 d-flex flex-wrap">
            <span class="filler d-md-none"></span>
            <div class="visionLegends w-100 d-flex flex-wrap align-items-center align-content-center">
                <span class="filler"></span>
                <div class="col-12 d-flex flex-wrap px-2 mb-2 align-items-center align-content-center">
                    <div class="col-12 col-md-3 color-1 fw-bold ts-12">Misión</div><div class="col-12  col-md-8 col-lg-7 col-xl-6 ts-10 text-center text-md-start color-3 fw-2 px-4 px-md-0">Generar momentos WOW a través del servicio, la calidad y experiencia.</div>
                </div>
                <span class="filler"></span>
                <div class="col-12 d-flex flex-wrap  px-2 mt-2 align-items-center align-content-center">
                    <div class="col-12 col-md-3 color-1 fw-bold ts-12">Visión</div><div class="col-12 col-md-8 col-lg-7 col-xl-6 ts-10 text-center text-md-start color-3 fw-2 px-4 px-md-0">Ser un referente de operación de restaurantes con marcas líderes e innovadoras en el mercado nacional</div>
                </div>
                <span class="filler"></span>
            </div>
            <span class="filler d-md-none"></span>

            <!-- <video autoplay muted loop playsinline>
                <source :src="a1m" type="video/mp4">
                <source :src="a1w" type="video/webm">
            </video> -->

            <img :src="a3" class="imageReplaceVideo">

        </div>

        <div class="color-1 fw-bold text-center w-100 my-5 ts-12">Nuestros pilares</div>

        <div class="d-flex w-100 flex-wrap justify-content-evenly align-content-start align-items-start px-4 px-md-5">

            <div class="d-flex w-100 flex-wrap justify-content-evenly align-content-start align-items-start px-0 px-md-5">
 
                <template v-for="pilar in pilars">
                    <div class="d-flex justify-content-center col-md-3 col-6 mt-4 mt-sm-2 mt-md-4 mb-3 mb-md-0 mb-lg-2 mb-xl-5">
                        <div class="col-10 squarePerfect color-4" :style="{'background-color':pilar.color}">
                            <span class="squareContent">
                                <img class="animation effect-4" :src="pilar.icon" alt="">
                            </span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center col-md-3 col-6 mt-4 mt-sm-2 mt-md-4 mb-3 mb-md-0 mb-lg-2 mb-xl-5">
                        <div class="col-10 squarePerfect color-4" :style="{'background-color':pilar.color}">
                            <span class="squareContent d-flex align-items-center align-content-center flex-wrap px-4 px-md-3 animation effect-10">
                                <div class="w-100 ts-4 fw-bold mb-2 mb-md-3">{{pilar.title}}</div>
                                <div class="w-100 ts-11">{{pilar.data}}</div>
                            </span>
                        </div>
                    </div>
                </template>

             </div>
        </div>


        <div class="color-1 fw-bold text-center w-100 my-5 ts-12">Nuestra historia</div>

        <div class="w-100 d-flex flex-wrap mt-4 mb-5">
            <div class="w-100 d-flex justify-content-center">

                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-l perspect">
                    <span class="squareContent animation effect-5 d-flex align-items-center align-content-center flex-wrap px-4 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-end pe-3">2001</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-end pe-3">Primer contacto con
                    la marca Bostons's
                    Pizza</div>
                    </span>
                </div>
                <div class="col-6 col-lg-5 col-xl-3"></div>

            </div>
            <div class="w-100 d-flex justify-content-center mt-2">

                <div class="col-6 col-lg-5 col-xl-3"></div>
                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-r perspect">
                    <span class="squareContent animation effect-6 d-flex align-items-center align-content-center flex-wrap px-3 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-start ps-3">2002</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-start ps-3">Primer Boston’s Pizza
                            fuera de Canadá y EUA,
                            ubicado en la ciudad
                            de Mérida
                        </div>
                    </span>
                </div>

            </div>
            <div class="w-100 d-flex justify-content-center">

                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-l perspect">
                    <span class="squareContent animation effect-5 d-flex align-items-center align-content-center flex-wrap px-4 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-end pe-3">2008</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-end pe-3">Segundo Boston’s Pizza
en Mérida</div>
                    </span>
                </div>
                <div class="col-6 col-lg-5 col-xl-3"></div>

            </div>
            <div class="w-100 d-flex justify-content-center mt-2">

                <div class="col-6 col-lg-5 col-xl-3"></div>
                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-r perspect">
                    <span class="squareContent animation effect-6 d-flex align-items-center align-content-center flex-wrap px-3 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-start ps-3">2013</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-start ps-3">Boston’s Pizza
en Campeche
                        </div>
                    </span>
                </div>

            </div>
            <div class="w-100 d-flex justify-content-center">

                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-l perspect">
                    <span class="squareContent animation effect-5  d-flex align-items-center align-content-center flex-wrap px-4 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-end pe-3">2015</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-end pe-3">Fusión con Grupo Penitova y Boston’s México.
Nace BPT Apertura Bostons Pizza, Santa Fe, CDMX
y La Parroquia de Veracruz
en City Center, Mérida</div>
                    </span>
                </div>
                <div class="col-6 col-lg-5 col-xl-3"></div>

            </div>
            <div class="w-100 d-flex justify-content-center mt-2">

                <div class="col-6 col-lg-5 col-xl-3"></div>
                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-r perspect">
                    <span class="squareContent animation effect-6 d-flex align-items-center align-content-center flex-wrap px-3 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-start ps-3">2016</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-start ps-3">Apertura La Parroquia
de Veracruz Palma, CDMX
                        </div>
                    </span>
                </div>

            </div>
            <div class="w-100 d-flex justify-content-center">

                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-l perspect">
                    <span class="squareContent animation effect-5  d-flex align-items-center align-content-center flex-wrap px-4 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-end pe-3">2019</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-end pe-3">Bostons Pizza y la Parroquia de Veracruz Caucel, Mérida</div>
                    </span>
                </div>
                <div class="col-6 col-lg-5 col-xl-3"></div>

            </div>
            <div class="w-100 d-flex justify-content-center mt-2">

                <div class="col-6 col-lg-5 col-xl-3"></div>
                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-r perspect">
                    <span class="squareContent animation effect-6 d-flex align-items-center align-content-center flex-wrap px-3 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-start ps-3">2021</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-start ps-3">Apertura Boston’s Pizza en el Estadio Kukulcán
Apertura Bostons Pizza Coapa en CDMX
                        </div>
                    </span>
                </div>

            </div>
            <div class="w-100 d-flex justify-content-center">

                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-l perspect">
                    <span class="squareContent animation effect-5  d-flex align-items-center align-content-center flex-wrap px-4 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-end pe-3">2022</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-end pe-3">Apertura de Sushi Roll
en Galerías, Campeche
Apertura La Parroquia
de Veracruz Campeche</div>
                    </span>
                </div>
                <div class="col-6 col-lg-5 col-xl-3"></div>

            </div>
            <div class="w-100 d-flex justify-content-center mt-2">

                <div class="col-6 col-lg-5 col-xl-3"></div>
                <div class="col-6 col-lg-5 col-xl-3 squarePerfect b-r">
                    <span class="squareContent animation effect-6 d-flex align-items-center align-content-center flex-wrap px-3 px-md-3">
                        <div class="w-100 fw-bold mb-2 mb-md-3 color-1 ts-13 text-start ps-3">2023</div>
                        <div class="w-100 ts-14 color-3 fw-bold text-start ps-3">Apertura Boston’s Pizza Patio Universidad
Apertura Santa Clara Villahermosa
Apertura Boston’s Pizza Querétaro
Apertura Santa Clara Altabrisa
                        </div>
                    </span>
                </div>

            </div>
        </div>

        <footer-info></footer-info>
    </div>
</template>
<script>

import portrait from "@/components/common/portrait.vue";
import footerInfo from "@/components/common/footer.vue";

export default {
    name:"wearea",
    emits:['event'],
    props:{
        con: {
            default: {},
            type: Object
        },
    },
    components:{
        portrait,
        footerInfo
    },
    mounted(){
        this.$parent.$parent.$emit('event', {type:'updateObserver'})
        this.$scrollInTop();
    },
    data(){
        return{
            a3: require("@/assets/images/banners/a3.jpg"),
            a1: require("@/assets/images/a1-2.jpg"),
            a29: require("@/assets/images/a29.png"),
            pilars:[
                {
                    icon:require('@/assets/images/a30.png'),
                    title:"Integridad:",
                    data:"El Respecto y Honestidad. Es nuestro pilar principal",
                    color:"#ab1a2e"
                },
                {
                    icon:require('@/assets/images/a31.png'),
                    title:"Pasión:",
                    data:"Nos encanta lo que hacemos y el porqué lo hacemos",
                    color:"#404041"
                },
                {
                    icon:require('@/assets/images/a32.png'),
                    title:"Compromiso:",
                    data:"Con nuestras familias, con la empresa y con México",
                    color:"#404041"
                },
                {
                    icon:require('@/assets/images/a33.png'),
                    title:"Colaboración:",
                    data:"Con el equipo para lograr metas y objetivos de manera eficiente",
                    color:"#ab1a2e"
                },
                {
                    icon:require('@/assets/images/a34.png'),
                    title:"Servicio:",
                    data:"Somos mucho más que buena comida, generamos experiencias con cada detalle",
                    color:"#ab1a2e"
                },
                {
                    icon:require('@/assets/images/a35.png'),
                    title:"Consistencia:",
                    data:"Esfuerzo continuo que ponemos en cada detalle",
                    color:"#404041"
                },
                {
                    icon:require('@/assets/images/a36.png'),
                    title:"Actitud:",
                    data:"V=(h+c)a",
                    color:"#404041"
                },
                {
                    icon:require('@/assets/images/a37.png'),
                    title:"1%",
                    data:"Buscamos ser el 1% mejor todos los días",
                    color:"#ab1a2e"
                },
            
            ]
        }
    }
}
</script>
<style lang="css" scoped>
    .logo img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .vision{
        display: flex;
        position: relative;
        min-height: 300px;
        background-color: black;
    }
    .vision .imageReplaceVideo{
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: 0;
        filter: blur(5px);
        -webkit-filter: blur(5px);
    }
    .visionLegends{
        position: relative;
        margin-top: 8%;
        min-height: 84%;
        margin-bottom: 8%;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.85);
    }

    .filler{
        width: 100%;
        height: 60px;
    }

    .squarePerfect{
        position: relative;
    }

    .squareContent{
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        left: 0;
        top:0
    }

    .squareContent img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .perspect{
        perspective: 2000px;
        transform-style: preserve-3d;
    }

    .b-l{
        border-right: 1px solid #ccc;
    }
    .b-r{
        border-left: 2px solid #aa182c;
    }


</style>