<template>
    <div class="d-flex flex-wrap w-100 align-items-start align-content-start justify-content-center">
        
        <portrait :image="a1" :phrase1="'Generamos'" :phrase2="'&nbsp;#MomentosWow'" ></portrait>

        <div class="col-12 d-flex justify-content-center py-4 mt-lg-5 mb-lg-5 mt-1 mb-1">
            <span class="col-lg-9 col-10 border border-bottom border-1 border-s-1"></span>
        </div>

        <div class="trademarks col-11 col-md-10 col-lg-9 px-2 d-flex flex-wrap align-items-center flex-lg-nowrap justify-content-center">
            <span class="col-6 col-md-3 col-lg-3 h-100 px-4 mb-3 mb-md-0 animation effect-1">
                <img :src="a7" class="h-100 w-100 object-fit-contain">
            </span>
            <span class="col-6 col-md-3 col-lg-3 h-100 px-4 mb-3 mb-md-0 animation effect-1">
                <img :src="a8" class="h-100 w-100 object-fit-contain">
            </span>
            <span class="col-6 col-md-3 col-lg-3 h-100 px-4 animation effect-1">
                <img :src="a9" class="h-100 w-100 object-fit-contain">
            </span>
            <span class="col-6 col-md-3 col-lg-3 h-100 px-4 animation effect-1">
                <img :src="a10" class="h-100 w-100 object-fit-contain">
            </span>
        </div>

        <div class="col-12 d-flex justify-content-center py-4 mt-lg-5 mb-lg-5 mt-0 mb-0">
            <span class="col-lg-9 col-10 border border-bottom border-1 border-s-1"></span>
        </div>

        <div class="w-100 legend text-center ts-5 fw-bold d-block mb-lg-5 mb-1 animation effect-1">
            Elevando las marcas más <br class="d-md-none"> brillantes hacia nuevas alturas en
            <br>
            colaboración con un gran equipo
        </div>

        <div class="col-12 d-flex justify-content-center py-4 mt-lg-5 mb-lg-5 mt-1 mb-1">
        </div>


        <div class="trademarksShow overflow-hidden w-100 d-flex flex-wrap flex-md-nowrap justify-content-evenly align-items-stretch mt-lg-5 mt-2">
            <span class="col-6 col-md-3 h-100 me-lg-4 mb-2 mb-sm-0 pe-1 pe-md-0 animation effect-1">
                <img :src="a2" class="h-100 w-100 object-fit-fill">
            </span>
            <span class="col-6 col-md-3 h-100 me-lg-4 ms-0 mb-2 mb-sm-0 ps-1 ps-md-0 animation effect-1">
                <img :src="a3" class="h-100 w-100 object-fit-fill">
            </span>
            <span class="col-6 col-md-3 h-100 me-lg-4 ms-0 pe-1 pe-md-0 animation effect-1">
                <img :src="a4" class="h-100 w-100 object-fit-fill">
            </span>
            <span class="col-6 col-md-3 h-100 ms-lg-0 ps-1 ps-md-0 animation effect-1">
                <img :src="a5" class="h-100 w-100 object-fit-fill">
            </span>
        </div>

        <footer-info></footer-info>

    </div>
</template>

<script>
// @ is an alias to /src
import portrait from "@/components/common/portrait.vue";
import footerInfo from "@/components/common/footer.vue";

export default {
    name: "HomeView",
    emits:['event'],
    props:{
        con: {
            default: {},
            type: Object
        },
    },
    mounted(){
        this.$scrollInTop();
        this.$parent.$parent.$emit('event', {type:'updateObserver'})
    },
    methods:{
        addAnim(elm){
            if(elm.classList.contains('effect-1')){
                elm.classList.add("effect-1-active");
            }
        },
        removeAnim(elm){
            if(elm.classList.contains('effect-1')){
                elm.classList.remove("effect-1-active");
            }
        },
    },
    components:{
        portrait,
        footerInfo
    },
    data() {
        return {
            a1: require("@/assets/images/a1-2.jpg"),
            a2: require("@/assets/images/a2.png"),
            a3: require("@/assets/images/a3.png"),
            a4: require("@/assets/images/a4.png"),
            a5: require("@/assets/images/a5.png"),
            a7: require("@/assets/images/a7.png"),
            a8: require("@/assets/images/a8.jpg"),
            a9: require("@/assets/images/a9.png"),
            a10: require("@/assets/images/a10.jpg"),
        };
    },
};
</script>

<style scoped>
    .trademarks{
        min-height: 120px;
    }

    .trademarksShow{
        min-height: 33vw;
    }

    .legend{
        line-height: 1.2em;
        color: var(--primary)
    }

    .animation.effect-1{
        opacity: 0;
        -webkit-transition: 0.8s ease;
        -moz-transition: 0.8s ease;
        -o-transition: 0.8s ease;
        -ms-transition: 0.8s ease;
        transition: 0.8s ease;
        transform: translateY(-5vw);
    }

    .animation.effect-1-active{
        opacity: 1 !important;
        transform: translateY(0vw) !important;
    }

</style>
