<template lang="html">
    <div class="footer w-100 d-flex flex-wrap overflow-hidden justify-content-start align-content-center align-items-center">
        <br>
        <br>
        <div class="col-xl-8 col-12 d-flex flex-wrap flex-md-nowrap overflow-hidden justify-content-start align-content-center align-items-center">
            <img :src="a78" alt="" class="ms-5 img-icon">
            <div class="col-xl-3 col-md-3 col-sm-9 col-8 d-flex flex-wrap py-4 justify-content-start align-items-center align-content-center ms-lg-5 ms-3">
                <div class="w-100 text-start ts-3 font-1 fw-bold">Horario Corporativo</div>
                <div class="w-100 text-start ts-23 font-3 fw-bold">Lunes – Viernes 09:00 – 18:00</div>
                <div class="w-100 text-start ts-23 font-3">Sábado y Domingo – CERRADO</div>
            </div>
            <img :src="a77" alt="" class="ms-5 img-icon">
            <div class="col-xl-5 col-md-6 col-8 d-flex flex-wrap py-4 justify-content-start align-items-center align-content-center ms-lg-5 ms-3">
                <div class="w-100 text-start ts-3 font-1 fw-bold">Calle 1B #273 D 42A x 40 y Prol. Montejo</div>
                <div class="w-100 text-start ts-23 font-3">col. Campestre, Mérida, Yucatán</div>
            </div>
        </div>
        <div class="col-xl-4 col-12 d-flex flex-nowrap overflow-hidden justify-content-end align-content-center align-items-center py-4">
            <div class="order-1 order-md-0 w-100 text-md-end text-start ts-23 font-3 p-0 me-md-3 me-0 ms-3 ms-md-0 fw-bold">(999) 948-44-04 / asosa@bptgroup.mx</div>
            <a class="order-0 order-md-1" href="https://www.linkedin.com/company/bptgroup"><img :src="a79" class="me-md-5 me-0 ms-5 ms-md-0 img-icon"></a>
        </div>
        <br>
        <br>
        <span class="d-block w-100 hr"></span>
        <div class="w-100 d-flex flex-wrap flex-md-nowrap mt-4 justify-content-center justify-content-md-between">
            <span class="col-12 col-md-8 col-lg-7 d-flex flex-wrap flex-md-nowrap align-content-stretch justify-content-center justify-content-md-start">
                <a class="col-5 col-md-3 imageCover bgWhite mx-2" href="https://www.bostons.com.mx">
                    <img :src="a25" class="pe-none">
                </a>
                <a class="col-5 col-md-3 imageCover bgWhite mx-2" href="https://laparroquiadeveracruz.com/">
                    <img :src="a26" class="pe-none">
                </a>
                <a class="col-5 col-md-3 imageCover bgWhite mx-2 mt-3 mt-md-0" href="http://www.santaclara.com.mx">
                    <img :src="a27" class="pe-none">
                </a>
                <a class="col-5 col-md-3 imageCover bgWhite mx-2 mt-3 mt-md-0" href="https://www.sushiroll.com.mx">
                    <img :src="a28" class="pe-none">
                </a>
            </span>
            <span class="col-4 col-md-2 col-lg-1 imageCover mx-md-2 mt-5 mt-md-0">
                <img :src="a29">
            </span>
        </div>
        <div class="w-100 text-center font-3 text-md-start ps-xl-4 ps-md-3 ps-0 mt-4 ts-min mb-5 fw-bold">
            Copyright © BPT Group - 2023 creado por <a class="text-decoration-none color-5" href="https://efisense.com">./Efisense</a>
        </div>
    </div>
</template>
<script>
export default {
    name:"footerPart",
    data(){
        return{
            a25: require("@/assets/images/a25.png"),
            a26: require("@/assets/images/a26.png"),
            a27: require("@/assets/images/a27.png"),
            a28: require("@/assets/images/a28.png"),
            a29: require("@/assets/images/a29.png"),
            a77: require("@/assets/images/a77.png"),
            a78: require("@/assets/images/a78.png"),
            a79: require("@/assets/images/a79.png"),
        }
    }
}
</script>
<style lang="css">
    .ts-min{
        font-size: 0.8vw;
    }
    .footer{
        min-height: 200px;
        background-color:#e2e2e2;
    }
    .hr { 
        background-color: #fff; 
        height: 2px; 
        border: 0; 
    }
    .imageCover{
        padding: 0 20px 0 20px;
        display: flex;
        align-content: center;
        align-items: center;
    }
    .bgWhite{
        background-color: #fff;
    }
    .bgBostons{
        background-color: #942933;
    }
    .imageCover img{
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .img-icon{
        width: 50px;
        height: 50px;
    }
</style>