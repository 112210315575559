<template lang="html">
        <div class="cLoader" v-if="active">
            <svg id="cut1" overflow=visible version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="200px" viewBox="0 0 205 204" enable-background="new 0 0 205 204" xml:space="preserve">
                <path fill="#85878A" opacity="1.000000" stroke="none" 
                    d="
                M55.381252,187.730499 
                    C42.432926,179.491501 31.533779,169.726868 23.363207,156.977020 
                    C15.646675,144.935669 10.902737,131.983414 8.915092,117.812706 
                    C5.580889,94.041870 10.026756,71.852715 23.166489,51.962936 
                    C37.779369,29.843229 58.287159,15.515866 84.523392,10.045884 
                    C105.814789,5.606849 126.654739,7.882607 145.860825,17.649624 
                    C175.590012,32.768040 194.274750,56.711983 199.010498,90.441910 
                    C199.580307,94.500366 199.932343,98.589409 199.704025,102.979286 
                    C193.361359,77.607330 180.293915,57.218384 157.502884,43.872398 
                    C134.580414,30.449436 110.174309,27.846970 85.643349,37.419838 
                    C52.804184,50.234871 33.587421,75.043907 30.220293,110.246063 
                    C26.792070,146.086960 46.748280,182.851715 88.909454,198.431686 
                    C83.054398,199.466537 72.778450,196.216370 55.381252,187.730499 
                z"/>
            </svg>

            <svg id="cut2" overflow=visible version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="200px" viewBox="0 0 205 204" enable-background="new 0 0 205 204" xml:space="preserve">
                <path fill="#58595B" opacity="1.000000" stroke="none" 
                    d="
                M104.896393,38.964108 
                    C110.675995,38.537464 116.005333,38.130505 121.334679,37.723545 
                    C121.495476,38.289997 121.656265,38.856449 121.817062,39.422905 
                    C98.950768,42.517834 81.107948,53.691540 69.155617,73.004257 
                    C57.008350,92.631973 55.198574,113.997658 63.430134,135.426071 
                    C72.038589,157.835617 88.783867,172.476410 111.886330,178.680405 
                    C135.008331,184.889679 156.136246,178.923355 175.155167,164.387024 
                    C175.550049,164.958344 175.944946,165.529648 176.339828,166.100967 
                    C172.490265,169.927948 168.965179,174.165604 164.733490,177.508957 
                    C148.667770,190.202072 130.251556,196.281265 109.799660,194.903488 
                    C91.464310,193.668289 75.238190,187.020737 61.667908,174.229477 
                    C48.986561,162.276138 40.811634,148.055328 37.773651,130.772446 
                    C33.950294,109.021637 38.156506,88.936821 50.731548,71.044624 
                    C62.934315,53.682114 80.111504,43.314125 100.958344,39.159771 
                    C102.087776,38.934708 103.282043,39.034966 104.896393,38.964108 
                z"/>
            </svg>

            <svg id="cut3" overflow=visible version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="200px" viewBox="0 0 205 204" enable-background="new 0 0 205 204" xml:space="preserve">
                <path fill="#404041" opacity="1.000000" stroke="none" 
                    d="
                M66.577621,103.810303 
                    C67.004295,101.519821 67.266716,99.626312 67.529144,97.732811 
                    C69.983696,131.192673 86.563713,152.935532 120.124504,157.528671 
                    C144.077179,160.806824 163.610153,149.921387 175.356644,128.654922 
                    C189.057571,103.850037 183.789032,80.770149 165.189957,59.924110 
                    C180.534592,66.028206 197.871353,92.655746 193.714447,120.673752 
                    C188.172256,158.028549 157.345581,180.412155 120.529305,175.725327 
                    C85.830643,171.308075 62.006451,139.105728 66.577621,103.810303 
                z"/>
            </svg>

            <svg id="cut4" overflow=visible version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="200px" viewBox="0 0 205 204" enable-background="new 0 0 205 204" xml:space="preserve">
                <path fill="#AB1A2D" opacity="1.000000" stroke="none" 
                    d="
                M82.980179,77.079269 
                    C92.137177,59.978661 106.636032,51.171661 125.198807,51.257538 
                    C147.305756,51.359818 165.197067,64.580635 170.909546,87.632378 
                    C171.781647,91.151596 171.834824,94.873764 171.604523,98.816399 
                    C165.730530,81.129730 154.237503,69.645248 136.008499,66.281311 
                    C125.429146,64.329018 115.192848,67.266815 106.425697,73.393517 
                    C90.648842,84.418755 77.364700,113.310799 102.351295,138.629013 
                    C84.439758,133.097092 69.903831,102.515594 82.980179,77.079269 
                z"/>
            </svg>
            <svg id="cut5" overflow=visible version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="200px" viewBox="0 0 205 204" enable-background="new 0 0 205 204" xml:space="preserve">
                <path fill="#AB1A2D" opacity="1.000000" stroke="none" 
                    d="
                M126.467873,132.935440 
                    C152.066788,134.368561 166.015518,111.844719 162.445343,93.646317 
                    C171.194092,104.471527 169.471451,120.449196 161.099899,132.153610 
                    C150.883698,146.437088 131.502914,150.641235 115.566414,142.716232 
                    C103.585899,136.758469 94.993904,120.446289 98.078346,112.134995 
                    C99.564384,113.964478 101.166649,115.287071 101.930916,116.986519 
                    C106.000404,126.035561 114.245338,129.133377 122.719330,131.971756 
                    C123.804222,132.335144 124.924736,132.592178 126.467873,132.935440 
                z"/>
            </svg>
        </div>
</template>
<script>
export default {
    name:"loaderAnim",
    props:{
        active:{
            default: false,
            type: Boolean
        }
    }
}
</script>
<style lang="css" scoped>
    .cLoader{
        background-color: rgba(119, 119, 119, 0.3);
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 9999;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .cLoader svg{
        position: absolute;
        margin: auto;
        transform: rotate(120deg);
        pointer-events: none;
    }

    #cut1{
        animation: spinInverse 5s linear  infinite;
    }
    #cut2{
        animation: spinInverse 5s linear .2s  infinite;
    }
    #cut3{
        animation: spinInverse 5s linear 0s  infinite;
    }
    #cut4{
        animation: spinInverse 5s linear .3s  infinite;
    }
    #cut5{
        animation: spinInverse 5s linear .3s  infinite;
        
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        40%{
            transform: rotate(1080deg);
        }
        80%{
            transform: rotate(1080deg);
        }
        100% {
            transform: rotate(1080deg);
        }
    }

    @keyframes spinInverse {
        0% {
            transform: rotate(0deg) scale(1);
        }
        30% {
            transform: rotate(-1080deg) scale(1);
        }
        40%{
            transform: rotate(-1080deg) scale(1.2);
        }
        50%{
            transform: rotate(-1080deg) scale(1);
        }
        60%{
            transform: rotate(-1080deg) scale(1.2);
        }
        70%{
            transform: rotate(-1080deg) scale(1);
        }
        80%{
            transform: rotate(-1080deg) scale(1.2);
        }
        90%{
            transform: rotate(-1080deg) scale(1);
        }
        95%{
            transform: rotate(-1080deg) scale(1.3);
        }
        100% {
            transform: rotate(-1080deg) scale(1);
        }
    }
</style>