<template lang="html">

    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition" :mode="route.meta.mode">
            <component :is="Component" :con="con"/>
        </transition>
    </router-view>

</template>
<script>



export default {
    name:"portal",
    emits:['event'],
    props:{
        con: {
            default: {},
            type: Object
        },
    },
}
</script>
<style lang="css">
    
</style>